import { useEffect, useRef } from 'react';

import { useSessionData } from '@confluence/session-data';
import { APP_NAVIGATION_METRIC } from '@confluence/browser-metrics/entry-points/app-navigation.metric';
import {
	createPageLoadMetric,
	isInitial,
	markBrowserMetricEnd,
	SPACE_OVERVIEW_LOAD_KEY,
	APP_NAVIGATION_SEGMENT_KEY,
	INLINE_COMMENTS_HIGHLIGHTS_SEGMENT_KEY,
	SPACE_NAVIGATION_SEGMENT_KEY,
	PAGE_TREE_SEGMENT_KEY,
	WATCH_BUTTON_SEGMENT_KEY,
	getWaterfallTimings,
} from '@confluence/browser-metrics';
import { INLINE_COMMENTS_HIGHLIGHTS_METRIC } from '@confluence/inline-comments/entry-points/pageSegmentLoadMetrics';
import { PAGE_TREE_METRIC } from '@confluence/page-tree/entry-points/pageSegmentLoadMetrics';
import { SPACE_NAVIGATION_METRIC } from '@confluence/side-navigation/entry-points/pageSegmentLoadMetrics';
import { markLegacyFY23TTI } from '@confluence/view-content-perf-metrics/entry-points/legacyFY23PerformanceMetrics';
import { CONTENT_RENDERER_METRIC } from '@confluence/view-content-perf-metrics/entry-points/pageSegmentLoadMetrics';
import { WATCH_BUTTON_METRIC } from '@confluence/watch-dialog/entry-points/pageSegmentLoadMetrics';

const baseConfig = {
	key: SPACE_OVERVIEW_LOAD_KEY,
	histogram: {
		// based on pre-production pollinator and prod p90
		initial: {
			fmp: '5000_6000_7000_8000_9000_10000_11000',
			tti: '5000_6000_7000_8000_9000_10000_11000',
		},
		transition: {
			fmp: '2000_2500_3000_3500_4000_4500_5000',
			tti: '2000_2500_3000_3500_4000_4500_5000',
		},
	},
	timings: [
		{ key: 'fy21-legacy-fmp', endMark: 'fy21-legacy-fmp' },
		{ key: 'fy21-legacy-tti', endMark: 'fy21-legacy-tti' },
		{ key: 'fy23-legacy-tti', endMark: 'fy23-legacy-tti' },
	],
};

const NOOP = () => {};

const getExpectedPageLoadSegments = ({
	isAnonymous,
	isInitialLoad,
}: {
	isAnonymous: boolean;
	isInitialLoad: boolean;
}) => {
	const segments = [CONTENT_RENDERER_METRIC, INLINE_COMMENTS_HIGHLIGHTS_METRIC];

	if (isInitialLoad) {
		segments.push(APP_NAVIGATION_METRIC, SPACE_NAVIGATION_METRIC, PAGE_TREE_METRIC);
	}

	if (!isAnonymous) {
		segments.push(WATCH_BUTTON_METRIC);
	}

	return segments;
};

/**
 * fmpAsUntilStopTimeMetrics
 *
 * What is minimally interactive?
 * Minimally interactive during the SSR phase at it's core, is determined if the component/element is clickable,
 * can register the action, and display a visual response that the action has been received.
 * The SSR phase can be stalled by appending ?NO_SPA=1 at the end of a CONNIE url.
 *
 * I.E. If you click on an inline comment/highlight, the click is registered, and displays a loading sidebar component.
 *
 * If a required segment is SSR'd and determined to be minimally interactive at FMP, ADD TO THIS LIST
 * If a required segment is SSR'd but determined to NOT be minimally interactive at FMP, DO NOT ADD TO THIS LIST
 * If a required segment is yet to be SSR'd, this config will not update TTI for that segment, by default is OFF.
 *
 * CONTEXT
 * the returned segments are considered to be minimally interactive during SSR
 * hence we want to use FMP (ssr-ttr mark)
 *
 * PageSegment TTI is measured: useFmpAsUntilStopTime ? FMP : Eventual load time
 *
 * Space Overview TTI is measured: Content Renderer TTI (Longest non minimally interactive page segment)
 *
 */
const fmpAsUntilStopTimeMetrics = (): string[] => {
	return [
		APP_NAVIGATION_SEGMENT_KEY,
		INLINE_COMMENTS_HIGHLIGHTS_SEGMENT_KEY,
		SPACE_NAVIGATION_SEGMENT_KEY,
		PAGE_TREE_SEGMENT_KEY,
		WATCH_BUTTON_SEGMENT_KEY,
	];
};

// This component assumes startBrowserMetricsPageLoad() has already been called from
// packages/confluence-frontend-server/src/components/Root/App.js in order to generate
// accurate pageLoad start timings. This function is supplementary and starts the
// `until` listeners which will automatically stop the pageLoad.
export const StartSpaceOverviewPageLoad = ({ spaceKey }: { spaceKey: string }) => {
	const { userId } = useSessionData();
	const pageLoadMetricRef = useRef<ReturnType<typeof createPageLoadMetric>>();

	// Create a page-load metric that will be finished based on the timings of each
	// of page-segment-load metrics we expect to see fired

	useEffect(() => {
		const expectedPageSegments = getExpectedPageLoadSegments({
			isAnonymous: Boolean(!userId),
			isInitialLoad: isInitial(),
		});

		// Create a list of untilPageSegments
		// This wraps the segments into an UntilExperience, so that @atlassian/browser-metrics can handle useFmpAsUntilStopTime gracefully
		// https://hello.atlassian.net/wiki/spaces/~484600559/pages/1515596554/Toolings+Update+Browser-Metrics+FMP+TTI
		const untilPageSegments = expectedPageSegments.map((segment) => ({
			experience: segment,
			useFmpAsUntilStopTime: fmpAsUntilStopTimeMetrics().includes(segment.key),
		}));

		pageLoadMetricRef.current = createPageLoadMetric({
			...baseConfig,
			timings: [...baseConfig.timings, ...getWaterfallTimings(expectedPageSegments)],
			include: expectedPageSegments,
			until: untilPageSegments,
		});

		if (!isInitial()) {
			// Make sure all segments are re-started and not currently marked as finished from a previous navigation
			expectedPageSegments.forEach((m) => m.startFromPageLoad());
		}

		pageLoadMetricRef.current.startPageLoad();
		pageLoadMetricRef.current.onStop(
			markBrowserMetricEnd.bind(null, pageLoadMetricRef.current),
			NOOP,
		);
		pageLoadMetricRef.current.onStop(
			(data) => markLegacyFY23TTI(pageLoadMetricRef.current, data),
			NOOP,
		);

		// Cleanup on unmount or when dependencies have changed
		return () => {
			pageLoadMetricRef.current?.cancel();
		};
	}, [spaceKey, userId]);

	return null;
};
