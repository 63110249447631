import type { FC, ReactNode } from 'react';
import React, { Fragment, useContext } from 'react';
import { styled } from '@compiled/react';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';
import { useQuery } from '@apollo/react-hooks';

import { token } from '@atlaskit/tokens';
import FeatureGates from '@atlaskit/feature-gate-js-client';

import { EditContentButtonWrapper } from '@confluence/edit-button';
import { usePageContentId } from '@confluence/page-context';
import { LoadingPriority } from '@confluence/loadable';
import { useCreateSpaceExperiment } from '@confluence/create-space/entry-points/createSpace';
import { HeaderItemsContainer } from '@confluence/header-items-manager';
import { createSingleQueryParamHook } from '@confluence/route-manager/entry-points/RouteState';
import { InlineCommentsLoader } from '@confluence/inline-comments/entry-points/InlineCommentsLoader';
import { QuickReload, PageMode } from '@confluence/quick-reload';
import { useBooleanFeatureFlag, useSessionData } from '@confluence/session-data';
import { ShareFeedbackButtonLoader } from '@confluence/company-hub-utils/entry-points/ShareFeedbackButtonLoader';
import { HubToolbarButtons } from '@confluence/company-hub/entry-points/HubToolbarButtons';
import {
	useViewPageParams,
	ViewPageContainer,
	ViewPageContent,
} from '@confluence/view-page-common';
import {
	DocumentUpdateStatusProvider,
	AnnotationsProviderIfEnabled,
} from '@confluence/annotation-provider-store';
import { RendererActionsProviderIfEnabled } from '@confluence/renderer-actions';
import {
	getRendererCoverPictureInfo,
	RendererPageCoverPicture,
} from '@confluence/page-cover-picture';
import { ContentViewedEvent, ContentRefetchProvider } from '@confluence/content-body';
import { InlineCommentsHighlighterLoader } from '@confluence/inline-comments/entry-points/InlineCommentsHighlighterLoader';
import { getEmojiFromPublishedPageProperties } from '@confluence/emoji-title/entry-points/transformer';
import { InlineCommentsContext } from '@confluence/comment-context';
import {
	useTitleContentPropertiesForPublishedPage,
	useIsFixedWidthImageOption,
} from '@confluence/custom-sites-extensions';
import { CUSTOM_SITES_PAGE_TITLE_FF } from '@confluence/emoji-title/entry-points/constants';
import { ReadingAidsAcronymsHighlighter } from '@confluence/contextual-reading-aids/entry-points/readingAidsAcronymsHighlighter';
import { SpaceOverviewQuery } from '@confluence/space-overview-query';
import { isCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';
import {
	CUSTOM_HEADER_FOOTER_TYPES,
	CustomHeaderFooterLoader,
} from '@confluence/custom-header-footer';

import { SpaceOverviewGroupedButtons } from './SpaceOverviewGroupedButtons';
import { StartSpaceOverviewPageLoad } from './perf.config';
import { SpaceHeading } from './SpaceHeader';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ContentBodyStyledComponent = styled.div<{
	isCompanyHub: boolean;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:global': {
		/* Fixes padding for overview tab in non fallback mode. It relies on a global selector to avoid breaking theming */
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'.wiki-content': {
			padding: '28px 0px',
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		iframe: {
			marginTop: token('space.negative.250', '-20px'),
		},
	},
	paddingTop: 0,
	paddingRight: `${token('space.500', '40px')}`,
	paddingBottom: `${token('space.250', '20px')}`,
	paddingLeft: `${token('space.500', '40px')}`,
	// added in for cover image
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	'--full-cover-image-margin': ({ isCompanyHub }) => (isCompanyHub ? '-42px' : '-40px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	'--full-cover-image-width': ({ isCompanyHub }) =>
		isCompanyHub ? 'calc(100% + 82px)' : 'calc(100% + 80px)',
});

export type SpaceOverviewProps = {
	spaceKey: string;
	children?: ReactNode;
};

const useFocusedCommentIdQuery = createSingleQueryParamHook('focusedCommentId');

const getEditPermissions = (homepage: { operations: [{ operation: string }] }) => {
	return homepage?.operations?.some(({ operation }) => operation === 'update');
};

export const SpaceOverview: FC<SpaceOverviewProps> = ({ spaceKey, children }) => {
	const { versionOverride } = useViewPageParams();
	const focusedCommentId = useFocusedCommentIdQuery();
	const { isSpaceTypesCreationFlowEnabled } = useCreateSpaceExperiment();
	const { isAdminHubAIEnabled, locale, isRovoEnabled } = useSessionData();
	const isReadingAidsAutoHighlightEnabled =
		(isAdminHubAIEnabled || isRovoEnabled) &&
		locale.startsWith('en') &&
		FeatureGates.getExperimentValue('cc_reading_aids_auto-highlight_acronyms', 'isEnabled', false);

	const isCustomSitesPageTitleFFOn = useBooleanFeatureFlag(CUSTOM_SITES_PAGE_TITLE_FF);
	const isNewHubGAExperienceEnabled = FeatureGates.checkGate(
		'confluence_frontend_company_hub_ga_updates',
	);

	const [contextContentId] = usePageContentId();
	const { shouldDisplaySidebar } = useContext(InlineCommentsContext);
	// @ts-ignore FIXME: `contextContentId` can be `undefined` here, and needs proper handling
	const contentId: string = contextContentId;

	// coverPictureWidth will be cleaned up once force-fixed width flag is cleaned up
	const { coverPictureWidth } = useTitleContentPropertiesForPublishedPage({
		contentId,
		isCustomSitesPageTitleFFOn,
	});
	const isFixedWidth = useIsFixedWidthImageOption({ coverPictureWidth });

	const isCompanyHub = isCompanyHubSpaceKey(spaceKey);

	const isFixedWidthImageOption = isCustomSitesPageTitleFFOn && !isCompanyHub && isFixedWidth;

	const { data, loading } = useQuery(SpaceOverviewQuery, {
		variables: {
			spaceKey,
			excludeOperations: Boolean(!isCompanyHub),
		},
	});
	const spaceName = data?.space?.name || '';
	const isThemed = data?.space?.theme?.themeKey || false;
	if (process.env.REACT_SSR && isThemed) {
		return null;
	}

	// The feature Company Hub uses a special space type "system" which is mostly
	// hidden from the UI and the user predominantly sees the space homepage /
	// overview presented with feature-specific customizations and referred to as
	// "front cover" or "landing page". Because the space is hidden from the user,
	// the UI concent "space icon" doesn't make sense.
	const spaceIconPath = isCompanyHub ? undefined : data?.space?.icon?.path;
	const emoji = getEmojiFromPublishedPageProperties(data?.space?.homepage);
	const hasEditPermissions = getEditPermissions(data?.space?.homepage);
	const isSpaceAdmin = data?.space?.currentUser?.isAdmin;

	const isSpaceIconEnabled = isSpaceTypesCreationFlowEnabled && !!spaceIconPath;

	const contentHeader: React.ReactNode = !isCompanyHub ? (
		<Subscribe to={[HeaderItemsContainer]}>
			{(headerItems: HeaderItemsContainer) => (
				<SpaceOverviewGroupedButtons
					spaceKey={spaceKey}
					contentId={contentId}
					addItem={headerItems.addItem}
					initItems={headerItems.initItems}
					versionOverride={versionOverride}
					spaceName={spaceName}
					isThemed={isThemed}
					spaceIconPath={spaceIconPath}
				/>
			)}
		</Subscribe>
	) : (
		<HubToolbarButtons
			spaceKey={spaceKey}
			spaceOverviewQueryLoading={loading}
			isSpaceAdmin={isSpaceAdmin}
			hasEditPermissions={hasEditPermissions}
			editButton={
				<EditContentButtonWrapper
					contentId={contentId}
					isCompanyHubButton={isNewHubGAExperienceEnabled}
				/>
			}
		/>
	);

	const isNewEditButtonEnabled = FeatureGates.checkGate(
		'confluence_frontend_company_hub_ga_updates',
	);
	// isContainerContentView adds the white gap between the cover image and the top nav for the content/breadcrumbs toolbar
	// In company hub, we only want this white space when the feature flag above is false and user has edit permissions.
	const isHubWhiteSpaceEnabled = isCompanyHub && !isNewEditButtonEnabled && !hasEditPermissions;
	const isContentToolbarGapEnabled =
		!isCompanyHub || (!isHubWhiteSpaceEnabled && !isNewEditButtonEnabled);

	return (
		<RendererActionsProviderIfEnabled>
			<>
				<StartSpaceOverviewPageLoad spaceKey={spaceKey} />
				<ViewPageContainer
					spaceKey={spaceKey}
					contentId={contentId}
					stickyHeader={contentHeader}
					isContainerContentView={isContentToolbarGapEnabled}
				>
					{({ contentQueryData, contentQueryError, isThemed }) => {
						const isFabricPage = Boolean(
							contentQueryData?.content?.nodes?.[0]?.body?.dynamic?.representation ===
								'atlas_doc_format',
						);

						const {
							coverPicture,
							contentAppearance,
							hasCoverPicture,
							coverPictureWidth,
							isTitleCenterAligned,
						} = getRendererCoverPictureInfo(contentQueryData);
						const isPageContentFullWidth = contentAppearance?.published === 'full-width';
						const hasEmoji = !!emoji || isSpaceIconEnabled;
						const isLeftAlignedTitleFullWidthPageWithFixedWidthImageAndEmoji =
							!isTitleCenterAligned &&
							isFixedWidthImageOption &&
							isPageContentFullWidth &&
							hasCoverPicture;

						return (
							<Fragment>
								{/* Body of content */}
								{/* Space Overview NVP non-theme uses StickyBanner inside of ViewPageContainer.
            Because StickyBanner is disabled when themes are enabled, need to display the
            header outside of content to match Space Overview Classic
            */}
								{isThemed && contentHeader}
								{!isCompanyHub && (
									<CustomHeaderFooterLoader type={CUSTOM_HEADER_FOOTER_TYPES.HEADER} />
								)}
								<ContentBodyStyledComponent
									isCompanyHub={isCompanyHub}
									data-testid="space-overview-content"
								>
									{hasCoverPicture && (
										<RendererPageCoverPicture
											contentId={contentId}
											isPagePreview={false}
											coverPicture={coverPicture}
											contentAppearance={contentAppearance}
											isCustomSitesPageTitleFFOn={isCustomSitesPageTitleFFOn}
											isSpaceOverview
											hasEmoji={hasEmoji}
											coverPictureWidthOverride={coverPictureWidth}
										/>
									)}
									<SpaceHeading
										{...{
											emoji,
											title: contentQueryData?.content?.nodes?.[0]?.title,
											isTitleCenterAligned,
											isCompanyHub,
											isCustomSitesPageTitleFFOn,
											contentAppearance: contentAppearance?.published,
											hasCoverPicture,
											spaceIconPath,
											isLeftAlignedTitleFullWidthPageWithFixedWidthImageAndEmoji,
											isPageContentFullWidth,
											isFixedWidthImageOption,
										}}
									/>
									{!isCompanyHub ? (
										<InlineCommentsHighlighterLoader
											pageId={contentId}
											isFabricPage={isFabricPage}
										/>
									) : null}
									{isReadingAidsAutoHighlightEnabled && (
										<ReadingAidsAcronymsHighlighter contentId={contentId} />
									)}
									<DocumentUpdateStatusProvider>
										<ContentRefetchProvider>
											<AnnotationsProviderIfEnabled>
												<ViewPageContent
													contentId={contentId}
													data={contentQueryData}
													error={contentQueryError}
													spaceKey={spaceKey}
													hasHighlightActions={!isCompanyHub}
													hasInlineComments={!isCompanyHub}
													isSpaceOverview
												/>
											</AnnotationsProviderIfEnabled>
										</ContentRefetchProvider>
									</DocumentUpdateStatusProvider>
									{!process.env.REACT_SSR && <ContentViewedEvent contentId={contentId} />}
									{!isCompanyHub && (
										<CustomHeaderFooterLoader type={CUSTOM_HEADER_FOOTER_TYPES.FOOTER} />
									)}
									<QuickReload
										contentId={contentId}
										contentType={contentQueryData?.content?.nodes?.[0]?.type}
										// The 'Date.now()' is expedient; see notes in quick-reload
										// package on what a more correct lastPollTime value would be.
										lastPollTime={Date.now()}
										pageMode={PageMode.RENDERER}
									/>
									{children}
								</ContentBodyStyledComponent>
								{!isCompanyHub && shouldDisplaySidebar && (
									<InlineCommentsLoader
										pageId={contentId}
										isFabricPage={isFabricPage}
										loadingPriority={
											window.__SSR_RENDERED__ && focusedCommentId
												? LoadingPriority.AFTER_PAINT
												: LoadingPriority.BACKGROUND
										}
										focusedCommentId={focusedCommentId}
									/>
								)}
								{isCompanyHub && <ShareFeedbackButtonLoader />}
							</Fragment>
						);
					}}
				</ViewPageContainer>
			</>
		</RendererActionsProviderIfEnabled>
	);
};
